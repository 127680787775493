<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-head">
        Welcome to the What'sTime
      </div>
      <div class="common-content-row">
        Welcome to the What'sTime website (hereinafter referred to as "the Website"). Please read
        the following terms of service ("Terms") carefully to ensure that you understand and agree
        to them. By accessing or using the Website, you agree to be bound by these Terms. If you do
        not agree to these Terms, please do not use the Website.
      </div>
      <div class="common-content-head">
        <span>1</span> Use License
      </div>
      <div class="common-content-row">
        The Website grants you a limited, non-exclusive, non-transferable license to access and use
        the Website in accordance with these Terms.
      </div>

      <div class="common-content-head">
        <span>2</span>User Conduct
      </div>

      <div class="common-content-row">
        You agree not to use the Website in any unlawful, infringing, defamatory, abusive,
        fraudulent, or otherwise inappropriate manner. You shall not interfere with or attempt to
        interfere with the operation of the Website.
      </div>

      <div class="common-content-head">
        <span>3</span>Intellectual Property
      </div>

      <div class="common-content-row">
        The Website and its content (including but not limited to text, images, logos, and software)
        are protected by copyright, trademark, and other intellectual property laws. You may not
        copy, modify, distribute, or otherwise use any content from the Website without explicit
        written permission from the Website.
      </div>

      <div class="common-content-head">
        <span>4</span>Disclaimer
      </div>

      <div class="common-content-row">
        The Website is provided &quot;as is&quot; without any warranties, express or implied. The
        Website does not warrant the accuracy, completeness, or suitability of the content provided.
        The Website shall not be liable for any direct or indirect damages arising from the use or
        inability to use the Website.
      </div>

      <div class="common-content-head">
        <span>5</span>Modification of Terms
      </div>

      <div class="common-content-row">
        The Website reserves the right to modify these Terms at any time. The modified Terms will be
        posted on the Website and will become effective immediately upon posting. Your continued use
        of the Website constitutes acceptance of the modified Terms.
      </div>

      <div class="common-content-head">
        <span>6</span>Termination
      </div>

      <div class="common-content-row">
        The Website reserves the right to terminate or restrict your access to the Website at any
        time, without notice, for any reason.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Terms of services',
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>